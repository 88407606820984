import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField, InputAdornment, IconButton, Switch, Typography, Stack, FormControlLabel } from '@mui/material';
import { Close, MenuOpenRounded } from '@mui/icons-material';
import { setFormValues } from '../../redux/slices/adminEditTemplateSlice';
import { setIsClosed } from '../../redux/slices/sideBySideWithVerticalNavSlice';

const DetailsPanel = () => {
    const dispatch = useDispatch();
    const { formMetadata, precedentUsers } = useSelector((state) => state.AdminEditTemplate);
    const { editTemplateData } = formMetadata;

    const findLastSavedByUserName = (userId) => {
        const user = precedentUsers.find((user) => user.userId === userId);
        return user ? `${user.firstName}${user.lastName ? ' ' + user.lastName : ''}` : userId;
    }

    const {
        userIdLastSavedBy,
        createdTs,
    } = editTemplateData;

    const { formFieldData } = useSelector((state) => state.AdminEditTemplate);
    const formTemplateName = formFieldData.templateName.value;

    const handleDetailsPanelClose = () => {
        dispatch(setIsClosed(true));
    }

    return (
        <Stack spacing={2} sx={{ pt: 1, px: 2, width: '100%' }}>

            <Stack direction={'row'} spacing={2} sx={{ width: '100%' }}>
                <Stack
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    direction={"row"}
                    sx={{
                        width: '100%',
                    }}
                >
                    <Typography variant="h6">Details</Typography>
                    <IconButton
                        onClick={handleDetailsPanelClose}
                    >
                        <MenuOpenRounded
                            sx={{
                                transform: 'scaleX(-1)'
                            }}
                        />
                    </IconButton>
                </Stack>



            </Stack>

            <Stack direction={'row'} spacing={2} sx={{ width: '100%' }}>
                <TextField
                    fullWidth
                    size='small'
                    label="Template name"
                    variant="outlined"
                    required
                    value={formTemplateName}
                    onChange={(e) => dispatch(setFormValues([{ field: 'templateName', value: e.target.value }]))}
                    error={formFieldData.templateName.error}
                    helperText={formFieldData.templateName.error}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => dispatch(setFormValues([{ field: 'templateName', value: '' }]))}
                                    size="small"
                                >
                                    <Close />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            <FormControlLabel
                label="Set as default label"
                control={
                    <Switch
                        checked={formFieldData.isCustomerDefault.value}
                        onChange={(e) => {
                            dispatch(setFormValues([{ field: 'isCustomerDefault', value: e.target.checked }]))
                        }}
                        name="isDefaultTemplate"
                        color="primary"
                        disabled={formFieldData.isCustomerDefault.initialValue === true}
                    />
                }
            />

            <Stack>
                <Typography sx={{ color: 'rgba(0, 0, 0, 0.54)' }} variant="body2">Last save</Typography>
                <Typography variant="body1">{new Date(createdTs * 1000).toLocaleDateString()}</Typography>
                <Typography variant="body2">{new Date(createdTs * 1000).toLocaleTimeString()}</Typography>
            </Stack>

            <Stack>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>Saved by</Typography>
                <Typography variant="body1">{findLastSavedByUserName(userIdLastSavedBy)}</Typography>
            </Stack>
        </Stack>
    );
};

export default DetailsPanel;